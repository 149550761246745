import {
    Loan,
    Loan_fee,
    Repay_Info_Stage,
    Repay_Info_All,
    Bank_list,
    Repay_Send_Sms,
    Repay_Submit,
    // Loan_Contract,
    Interface_url
} from '../services/loan';
import {Client_auth_get} from "../services";

export default {
    namespace: 'NSLoan',
    state: {
        name: 'NSLoan',
        ClientInfo: {},
        LoanFee: null,
        BankListInfo: [],
        RepayList: null,
        LoanInfo: null,
        PayInfo: null,
    },
    reducers: {
        redu_client_info(state, {payload}) {
            return {
                ...state,
                ClientInfo: payload
            }
        },
        redu_loan_fee(state, {payload}) {
            return {
                ...state,
                LoanFee: {...payload}
            }
        },
        redu_bank_list(state, {payload}) {
            return {
                ...state,
                BankListInfo: payload
            }
        },
        redu_stage_info(state, {payload}) {
            return {
                ...state,
                RepayList: payload.planRemindList,
                LoanInfo: payload.loanVO
            }
        },
        redu_repay_info(state, {payload}) {
            return {
                ...state,
                PayInfo: payload
            }
        }
    },
    effects: {
        * loan_init({payload, callback}, {put, call}) {
        /*借款*/
        const res = yield call(Interface_url, {...payload});
        if (res.success) {
            if (callback && typeof callback === 'function') {
                callback(res);
            }
        }
    },
        /* 獲取用戶授信額度*/
        * eff_client_info({payload, callback}, {put, call, select}) {
            const token = yield select(state => state.token);
            if (token !== null) {
                const res = yield call(Client_auth_get, {...payload});
                if (res.success) {
                    yield put({type: 'redu_client_info', payload: res.result});
                    callback && callback(res);
                }
            }
        },
        
        * loan_create({payload, callback}, {put, call}) {
            /*借款*/
            const res = yield call(Loan, {...payload});
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * loan_fee({payload, callback}, {put, call}) {
            /*借款计算服务费*/
            const res = yield call(Loan_fee, {...payload});
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
                yield put({type: 'redu_loan_fee', payload: res.result});
            }
        },
        * eff_bank_list({payload, callback}, {put, call}) {
            const res = yield call(Bank_list, {...payload});
            if (res.success) {
                yield put({type: 'redu_bank_list', payload: res.result});
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        
        * repay_stage_info({payload, callback}, {put, call}) {
            /*还款详情*/
            const res = yield call(Repay_Info_Stage, {...payload});
            if (res.success) {
                yield put({type: 'redu_stage_info', payload: res.result});
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        * repay_all_info({payload, callback}, {put, call}) {
            /*借款详情*/
            const res = yield call(Repay_Info_All, {...payload});
            if (res.success) {
                yield put({type: 'redu_repay_info', payload: res.result});
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        * repay_send_sms({payload, callback}, {put, call}) {
            /*借款详情*/
            const res = yield call(Repay_Send_Sms, {...payload});
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * repay_submit({payload, callback}, {put, call}) {
            /*借款详情*/
            const res = yield call(Repay_Submit, {...payload});
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        // * loan_contract_info({payload, callback}, {put, call}) {
        //     /*借款详情*/
        //     const res = yield call(Loan_Contract, {...payload});
        //     if (res.success) {
        //         callback && callback(res.result);
        //     }
        // }
    }
};
