import fecth from "../utils/request";

// 订单列表
const orderList = (params) => {
    return fecth('sony/order/list', params)
}

// 账单详情
const getCurrentPayPlan =  (params) => {
    return fecth('sony/repay/plan/list', params)
}

  // 订单详情
const orderDetail = (params) => {
    return fecth('sony/order/detail', params)
}

// 获取广告位或专区下的spu
const homeList =  (params) => {
    return fecth(`vivo/zone/goods/${params}`, '', 'get');
}

export {
    orderList,
    getCurrentPayPlan,
    orderDetail,
    homeList
}