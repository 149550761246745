import React from 'react';
import {Tabs,Badge} from 'antd-mobile';
import {connect} from 'dva';
import './index.less';
import APP from '../../utils/APP';
import localStorage from 'localStorage';
import { Btn, Layout } from '../../components';
import {money, ChangeUrlH5} from "../../utils";

import {orderList} from '../../config/requestApi'

const navigation={
    title: "我的订单",
    // left: true,
}
class Index extends React.Component {
    constructor(...props) {
        super(...props);
        this.state = {
            isLogin: false,
            isShow: false,
            tabActiveIndex: 0 ,//tabs切换
            orderCurrentList: [],//未完成
            orderFinishList: [],  //已完成
        }
    }

    componentDidMount() {
        this.init(0)
    }
    async init(tabActiveIndex) {
        try {
            const params = {
                memberId: localStorage.getItem('userId'),
                type: tabActiveIndex,
            }
            const {code, data} = await orderList(params)
            if (code == 200) {
                if (tabActiveIndex) {
                    this.setState({
                        orderFinishList: data,
                        orderCurrentList: []
                    })
                } else {
                    this.setState({
                        orderFinishList: [],
                        orderCurrentList: data
                    })              
                }
            }
        } catch (error) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userId')
            this.props.history.push("/login");
        }
        // const {dispatch} = this.props;
        // dispatch({
        //     type: 'NSApp/eff_request',
        //     payload: {
        //         at: "order.list",
        //         v: "1.0.0" 
        //     },
        //     callback: (res) => {
        //         this.setState({
        //             orderCurrentList: res.order_current_list,
        //             orderFinishList: res.order_finish_list,
        //         })
        //     }
        // })
    }
    render() {
        const { orderCurrentList, orderFinishList, } = this.state;
        let tabActiveIndex = this.state.tabActiveIndex;
        //无账单
            let billNull = <div className={'bill-null'}>
            <div className={'pic'}></div>
            {/* <div className={'btnNs'} onClick={() => {
                window.localStorage.setItem("tabBarName", "index");
                this.props.history.push('/')
                }}>
                    <span className={"textBN"}>去逛逛</span>
            </div> */}
        </div>
        //内容显示
        let RenderPro, proBill, btnPro, ProFinish;
        //当前订单
        if (orderCurrentList !== null && orderCurrentList !== undefined && orderCurrentList.length>0) {
            proBill = orderCurrentList.map((item, index) => {
                const status = item.resultStatus;
                localStorage.setItem('status', 'status');
                // console.log(localStorage.getItem('status'),8765)
                    // let btnC = 
                    // let btnP = 
                    // let btnL = 
                    //判断显示按钮的状态
                    if (status === 5) {
                        btnPro=<div className={"footers"}
                        onClick={() => {
                            this.props.history.push({
                                pathname: '/myBill',
                                search:"?orderNo="+item.orderId
                            })
                        }}>
                        <span className={"btnText"}>查看账单</span>
                    </div>
                    } else {
                        btnPro='';
                    }
                    // if (status=="UNPAID") {
                    //     btnPro=<div className={"footers"}
                    //         onClick={() => {
                    //             this.props.history.push({
                    //                 pathname: '/payConfirm',
                    //                 search:"?orderNo="+item.orderNo
                    //             })
                    //         }}>
                    //     <span className={"btnText"}>去支付</span>
                    // </div>
                    // } else if (status=="UNCONFIRM") { 
                    //     btnPro=<div className={"footers"}
                    //         onClick={() => {
                    //             this.props.history.push({
                    //                 pathname: '/billConfirm',
                    //                 search:"?orderNo="+item.orderNo
                    //             })
                    //         }}>
                    //         <span className={"btnText"}>去确认</span>
                    //     </div>
                    // } else if (status == "UN_BACK") {
                    //     btnPro=<div className={"footers"}
                    //         onClick={() => {
                    //             this.props.history.push({
                    //                 pathname: '/myBill',
                    //                 search:"?orderNo="+item.orderNo
                    //             })
                    //         }}>
                    //         <span className={"btnText"}>查看账单</span>
                    //     </div>
                    // } else if (status == "UN_DELIVER") {
                    //     btnPro='';
                    // }
                localStorage.setItem('status',status)
                return (
                    <div className={'module BtnBill'} key={index}>
                        <div className={'card'}>
                            {/* 头部 */}
                            <div className={'headTop'}>
                                <div className={'text'}>{item.resultStatusStr}</div>
                                <div className={'date'}>{item.gmtCreated}</div>
                            </div>
                            {/* 内容 */}
                            <div className={'contain'} onClick={() => {
                                this.props.history.push({
                                    pathname: "/orderDetails",
                                    search: `?orderNo=${item.orderId}&status=${item.status}&resultStatusStr=${item.resultStatusStr}`,
                                })
                            }}>
                                <div className={'left'}>
                                    <img src={item.pic}></img>
                                </div>
                                <div className={'right'}>
                                    <div>{item.goodsName}</div>
                                    <div><span>月租金:</span><span>￥{item.monthlyPrice}</span></div>
                                    <div><span>租期:</span><span>{item.planIndex}个月</span></div>
                                </div>
                            </div>
                            {btnPro}
                        </div>
                    </div>
                )
                
            })
        } else {
            proBill=billNull
        }
        //已完成订单
        if (orderFinishList !== null && orderFinishList !== undefined && orderFinishList.length>0) {
            ProFinish = orderFinishList && orderFinishList.map((item,index) => {
                return (
                    <div className={'module'} key={index}>
                        <div className={'card'}>
                            {/* 头部 */}
                            <div className={'headTop'}>
                                <div className={'text'}>{item.resultStatusStr}</div>
                                <div className={'date'}>{item.gmtCreated}</div>
                            </div>
                            {/* 内容 */}
                            <div className={'contain'} onClick={() => {
                                // this.props.history.push({
                                //     pathname: "/orderDetails",
                                //     search: "?orderNo="+item.orderId
                                // })
                            }}>
                                <div className={'left'}>
                                    <img src={item.pic}></img>
                                </div>
                                <div className={'right'}>
                                    <div>{item.goodsName}</div>
                                    <div><span>月租金:</span><span>￥{item.monthlyPrice}</span></div>
                                    <div><span>租期:</span><span>{item.planIndex}个月</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            ProFinish=billNull
        }

    
       //根据状态显示不同的样式内容
        const stu = localStorage.getItem('status');
    
        return (
            <Layout tabBar={true} navigation={navigation} >
                <div className={'index'}>
                    {/* tabs切换 */}
                    <div className="m-sys-header">
									<div className="m-sys-tab-wrap">
										<div className={"m-sys-tab "} >											
											<span className={"m-sys-tab-text "+ (tabActiveIndex === 0 ? 'active': '')} onClick={this.handleTabClick.bind(this, 0)}>当前订单</span>
                                            
										</div>
										<div className={"m-sys-tab " } >								
											<span className={"m-sys-tab-text "+ (tabActiveIndex === 1 ? 'active': '') } onClick={this.handleTabClick.bind(this, 1)}>已完成</span>
											
										</div>										
									</div>
                    </div>
                    {/* 对应内容显示 */}
                    <div className="m-sys-content">
						<div className={"m-sys-view " + (tabActiveIndex === 0 ? 'active': '')}>
							<div className="mbox">
                                {proBill}
							</div>
						</div>
						<div className={"m-sys-view " + (tabActiveIndex === 1 ? 'active': '')}>
							<div className="mbox">
                                {ProFinish}
                            </div>
                        </div>															
					</div>
                </div>
            </Layout>
        )
    }
}
Object.assign(Index.prototype, {
	handleTabClick(tabActiveIndex) {
		this.setState({
			tabActiveIndex
		})
        this.init(tabActiveIndex)
	}
})
export default connect(({NSApp}) => ({NSApp}))(Index);
