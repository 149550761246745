/**
 * Created by mmcai on 2015/11/30.
 * QQ:3248544136
 * Email:caimengmeng@docard.net
 * update:2015/11/30
 */

// const APP = window.APP;
module.exports = {
    name: "APP",
    IOS: navigator.userAgent.match(/Mac OS/),
    IPHONE: navigator.userAgent.indexOf('iPhone') > -1,
    ANDROID: navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1,
    BROWSER: {
        iPhone: navigator.userAgent.indexOf('iPhone') > -1,
        android: navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1,
        ios: navigator.userAgent.match(/Mac OS/), // ios
        width: window.innerWidth,
        height: window.innerHeight,
        isclient: navigator.userAgent.indexOf("ESD") > -1 || navigator.userAgent.indexOf("YYYQ") > -1
    },
    getLocParams: function (name) {
        const href = window.location.href,
            subIndex = href.indexOf("?"),
            paramsObj = {};
        if (subIndex !== -1) {
            const params = href.substr(subIndex + 1);
            const paramsMany = params.indexOf("&");
            if (paramsMany !== -1) {
                const paramsArr = params.split("&");
                paramsArr.forEach(function (item) {
                    let val = item.split("=")[1];
                    let i = val.indexOf('#');
                    if (i !== -1) {
                        val = val.substring(0, i)
                    }
                    paramsObj[item.split("=")[0]] = val;
                });
            } else {
                let val = params.split("=")[1];

                let i = val.indexOf('#');
                if (i !== -1) {
                    val = val.substring(0, i)
                }
                paramsObj[params.split("=")[0]] = val;
            }
        }
        if (paramsObj.hasOwnProperty(name)) {
            return paramsObj[name];
        } else {
            return null;
        }
    },
    // 获取设备信息
    DEVICE: function () {
        return new Promise(function (resolve, reject) {
            document.addEventListener('WinJSBridgeReady', function () {
                window.WinJSBridge.call("getDeviceInfo", function (res) {
                    if (res) {
                        resolve(res)
                    } else {
                        reject({"msg": "获取设备信息报错"})
                    }
                });
            });
        });
    },
    // 隐藏 TitleBar
    HIDE_TITLE_BAR: function () {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("hideTitleBar");
        });
    },
    // 显示 TilteBar
    SHOW_TITLE_BAR: function () {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("showTitleBar");
        });
    },
    SET_TITLE: function (title) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("setTitle", title);
        });
    },
    JUMP: function (url, title) {
        if (this.BROWSER.isclient) {
            document.addEventListener('WinJSBridgeReady', function () {
                window.WinJSBridge.call("jumpUrl", {
                    url,
                    title: title ? title : ""
                });
            });
        } else {
            window.location.href = url;
        }
    },
    JUMP_TO: function (url) {

    },
    JUMP_H5: function (url, title) {
        this.JUMP(url, title)
    },
    JUMP_NATIVE: function (url) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("jumpUrl", {
                url: `winwallet://${url}`,
                title: ""
            });
        });
    },
    GET_CONTACT: function (callback) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("getContacts", function (resp) {
                callback && callback(resp)
            });
        });
    },
    UPLOAD_DATA: function (callback) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("getAllContacts", function (resp) {
                callback && callback(resp)
            });
        });
    },
    GO_BACK: function (num) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("back", num);
        });
    },
    PAGE_WILL_LOAD: function (callback) {
        document.addEventListener('WinJSBridgeReady', function (event) {
            window.WinJSBridge.call("onPageVisible", function (resp) {
                callback && callback(resp)
            });
        });
    },
    PAGE_SHOW: function (callback) {
        document.addEventListener('WinJSBridgeReady', function (event) {
            window.WinJSBridge.call("onPageShow", function (resp) {
                callback && callback(resp)
            });
        });
    },
    PAGE_HIDE: function (callback) {
        document.addEventListener('WinJSBridgeReady', function (event) {
            window.WinJSBridge.call("onPageHide", function (resp) {
                callback && callback(resp)
            });
        });
    },
    /*主动刷新*/
    REFRESH: function () {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("reRresh");
        });
    },
    /*下拉刷新*/
    SET_REFESH: function () {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("reFresh");
        });
    },
    IS_LOGIN: function (callback) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("isLogin", function (resp) {
                callback && callback(resp)
            });
        });
    },

    // 选项卡切换 0 = 首页  1 = 账单
    JUMP_TAB: function (num) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("tabClick", `${num}`);
        });
    },    // 选项卡切换 0 = 首页  1 = 账单
    IS_SHOW_BACK: function (flag) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("hideBackBtn", `${flag}`);
        });
    },
     // 隐藏键盘
     IS_HIDE_INPUT: function () {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("hideSoftInput");
        });
    },
    /*弹框*/
    DIALOG_SHOW: function (title = "", content = "", callback) {
        document.addEventListener('WinJSBridgeReady', function () {
            window.WinJSBridge.call("dialogAndroid", {title, content}, function (res) {
                callback && callback(res);
            });
        });
    }
   
   
};
