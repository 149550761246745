import {request, api} from '../utils/index';

const {
  loan_submit,
  loan_fee,
  init_pay_stage,
  init_pay_all,
  init_Bank_list,
  init_pay_sms,
  pay_submit,
  loan_contract,
  interface_url
} = api;


/*借款*/
export function Loan(data) {
  return request({
    url: loan_submit,
    method: 'post',
    data,
  })
}


/*借款服务费*/
export function Loan_fee(data) {
  return request({
    url: loan_fee,
    method: 'post',
    data,
  })
}



/*分期还款数据*/
export function Repay_Info_Stage(data) {
  return request({
    url: init_pay_stage,
    method: 'post',
    data,
  })
}

/*全部结清数据*/
export function Repay_Info_All(data) {
  return request({
    url: init_pay_all,
    method: 'post',
    data,
  })
}

/*银行卡列表*/
export function Bank_list(data) {
  return request({
    url: init_Bank_list,
    method: 'post',
    data,
  })
}

/*还款短信*/
export function Repay_Send_Sms(data) {
  return request({
    url: init_pay_sms,
    method: 'post',
    data,
  })
}

/*还款提交*/
export function Repay_Submit(data) {
  return request({
    url: pay_submit,
    method: 'post',
    data,
  })
}

/*还款提交*/
export function Interface_url(data) {
  return request({
    url: interface_url,
    method: 'get',
    data,
  })
}
