import React from 'react';
import {Router, Route, Switch} from 'dva/router';
import dynamic from 'dva/dynamic';
import RouterConfig from './config/routes';
// import APP from './routes/index'
import Bill from './routes/bill'

// 把路由组件暴露
export default ({history, app}) => {
  return (
    <Router history={history}>
      <div className={'root'}>
        <Switch>
          {/* 首页 */}
          <Route path="/" exact component={Bill}/>
          {
            RouterConfig.map(({path, models, component, ...dynamics}, index) => {
              return (
                <Route
                  key={index}
                  path={path}
                  exact
                  component={dynamic({app, models, component})}
                />
              )
            })
          }
        </Switch>
      </div>
    </Router>
  )
}
