import APP from '../utils/APP';
import {SearchParams} from "../utils";
import {request, api} from '../utils/index';
import { Toast } from 'antd-mobile';

const Interface_url = function (data) {
    return request({
        url: api.interface_url,
        method: 'post',
        data,
    })
};

const {BROWSER} = APP;
const GetToken = () => new Promise(resolve => {
    const search = SearchParams(window.location.search);
    const clientNo = search.get('clientNo');
    window.localStorage.setItem('clientNo', clientNo);
    resolve(clientNo);
});

const GetLoginFlag = () => new Promise(resolve => {
    APP.IS_LOGIN(function (flag) {
        const isLogin = flag !== 'false';
        resolve(isLogin);
    });
});


export default {
    namespace: 'NSApp',
    state: {
        index: {},
        authList: {},
        userList: {},
        MyList:{},
        bill: null,
        baseInfo: {},
        clientNo: null,
        feedBackType: null,
        login: {},
        // iphone:{},
        mobile: '',
        verifyCode: '',
        //专区
        prefectrue: {},
        Description:{},
        ossIndefied: {},
        faceres: {},
        baseList: null,
        addressList: null,
        addressD:null,
        userInfo: {},
        orderBill: null,
        orderList: null,
        addressNew:null
    },
    reducers: {

        //首页
        updateIndex: function (state, { payload }) {
            return {
                state,
                index: payload
            }
        },
        //信息认证列表
        updateAuthList: function (state, {payload}) {
            return {
                state,
                authList: payload
            }
        },
          //授信
        updateUserDespoitStatus: function (state, {payload}) {
            return {
                state,
                userDespoitStatus: payload
            }
        },
        //身份证ocr
        // updateAuthOcr: function (state, {payload}) {
        //     return {
        //         state,
        //         authList: payload
        //     }
        // },
        //实名认证信息
        updateAuthIde:function (state, {payload}) {
            return {
                state,
                userInfo: payload
            }
        },
        //活体
        updateFace: function (state, {payload}) {
            return {
                state,
                faceres: payload
            }
        },
        //oss获取验签
        updateOss: function (state, {payload}) {
            return {
                state,
                ossIndefied: payload
            }
        },
        //我的账单
        updatemyBill: function (state, {payload}) {
            return {
                state,
                bill: payload
            }
        },
        //订单列表
        updateBill: function (state, { payload }) {
            return {
                state,
                orderBill: payload
            }
        },
        //订单详情
        updateorder: function (state, { payload }) {
            return {
                state,
                orderList: payload
            }
        },
        //个人基础信息的
        updateBase: function (state, {payload}) {
            return {
                state,
                baseInfo: payload,
            }
        },
        updateB: function (state, {payload}) {
            return {
                state,
                baseList: payload,
            }
        },
        //登录  发验证码
        updateIphone: function (state, { payload }) {
            return {
                state,
                verifyCode: payload
            }
        },
        //用户登录信息
        updateV: function (state, { payload }) {
            return {
                state,
                userList: payload
            }
        },
        // my list 
        updateMyList: function (state, { payload }) {
            return {
                state,
                MyList: payload
            }
        },
        //login页协议
        updataLogin: function (state, { payload }) {
            return {
                state,
                login:payload
            }
        },
        //专区
        updataP: function (state, { payload }) {
            return {
                state,
                prefectrue:payload
            }
        },
        //详情
        updataD: function (state, { payload }) {
            return {
                state,
                Description:payload
            }
        },
        //添加用户的新地址
        updataAddress: function (state, { payload }) {
            return {
                state,
                addressList:payload
            }
        },
        //用户地址修改
        updataAdel: function (state, { payload }) {
            return {
                state,
                addressD:payload
            }
        },
        //修改
        updataEdit: function (state, { payload }) {
            return {
                state,
                addressNew:payload
            }
        },
        /*Front*/
        redu_update_clientNo(state, {payload}) {
            return {
                ...state,
                clientNo: payload
            }
        },
    },
    effects: {
        * eff_request({payload, callback}, {put, call}) {
            const res = yield call(Interface_url, { ...payload });
            console.log(res, 222222)
            // Toast.info(res.message,2)
            if (res.succ) {
                const {at} = payload;
                const result = JSON.parse(res.desData);

                switch (at) {
                    case "thirddata.auth.submit":
                        yield put({type: "updateUserDespoitStatus", payload: result});
                        break;
                    // case "client.simpleinfo.init":
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    //登录
                    case "auth.verify.send":
                        yield put({ type: 'updateIphone', payload: result });
                        break;
                    case "auth.verify.login":
                        yield put({ type: 'updateV', payload: result });
                        break;
                    // 我的 user.index
                    case "user.index":
                        yield put({ type: 'updateMyList', payload: result });
                        
                        break;
                    case "auth.init":
                        yield put({ type: 'updataLogin', payload: result });
                        break;
                    //首页
                    case "app.index":
                        yield put({ type: 'updateIndex', payload: result });
                        break;
                    //专区
                    case "product.lsit":
                            yield put({ type: 'updataP', payload: result });
                        break;
                    //详情
                    case "product.detail":
                        yield put({ type: 'updataD', payload: result });
                        break;
                    // 认证列表
                    case "thirddata.auth.list":
                        yield put({type: "updateAuthList", payload: result});
                        break;
                    //首次风控测试
                    case "thirddata.auth.submit":
                        yield put({type: "updateUserDespoitStatus", payload: result});
                        break;
                    //实名认证
                    case "app.realname":
                        yield put({type: "updateAuthIde", payload: result});
                        break;
                    //身份证ocr
                    case "ocr.idcard":
                        yield put({type: "updateAuthOcr", payload: result});
                        break;
                    //oss配置
                    case "oss.upload.config":
                        yield put({ type: "updateOss", payload: result });
            
                        break;
                    //人脸识别
                    case "face.recognition":
                        yield put({ type: "updateFace", payload: result });
                        break;
                    //个人基础信息页面初始化
                    case "client.simpleinfo.init":
                        yield put({ type: "updateBase", payload: result });
                        break;
                    //个人基础信息保存
                    case "client.simpleinfo.save":
                        yield put({ type: "updateB", payload: result });
                        break;
                    //添加新地址
                    case "user.address.ins":
                        yield put({ type: 'updataAddress', payload: result });
                        break;
                    //初始化地址
                    case "user.address.detail":
                        yield put({ type: 'updataAdel', payload: result });
                        break;
                    //修改地址
                    case "user.address.mod":
                            yield put({ type: 'updataEdit', payload: result });
                            break;
                    //我的账单
                    case "bills.unreturned.list":
                        yield put({ type: 'updatemyBill', payload: result });
                        break;
                    //订单列表
                    case "order.list":
                        yield put({ type: 'updateBill', payload: result });
                        break;
                    //订单详情
                    case "order.detail":
                        yield put({ type: 'updateorder', payload: result });
                        break;
                    //取消订单
                    case "order.cancel":
                        yield put({ type: 'update', payload: result });
                        break;
                
                    default:
                        console.log('其他接口');
                        break;
                }
                callback && callback(result);
            }
        },

        /*从Url当中获取token 异步封装*/
        * eff_init_token({payload, callback}, {put, call}) {
            const res = yield call(GetToken, {...payload});
            if (res) {
                yield put({type: 'redu_update_clientNo', payload: res});
                callback && callback(res);
            }
        },

        /*dispatch clientNo 当用户首页能够拿到ClientNo的时候，就保存到本地*/
        * eff_save_clientNo({payload, callback}, {put}) {
            const {clientNo} = payload;
            yield put({type: 'redu_update_clientNo', payload: clientNo});
            callback && callback(clientNo);
        },
    },
    subscriptions: {
        setup({dispatch, history}) {

            // 更新登录状态
            // dispatch({type: 'eff_init_isLogin', payload: {}});

            // 路由监听
            history.listen((location) => {
                const pathname = location.pathname;
                if(pathname != '/prefecture' ){
                    window.localStorage.removeItem('brandId');
                }


                switch (pathname) {
                    // case "/baseInfo":
                    //     document.title = "基础信息";
                    //     BROWSER.isclient ? APP.SET_TITLE('基础信息') : document.title = '基础信息';
                    //     break;
                    // case "/bill":
                    //     break;
                    // case "/applyResult":
                    //     document.title = "申请成功";
                    //     BROWSER.isclient ? APP.SET_TITLE('申请成功') : document.title = '申请成功';
                    //     break;

                    /*Front*/
                    // case "/loan":
                    //     document.title = "借款";
                    //     BROWSER.isclient ? APP.SET_TITLE('借款') : document.title = '借款';
                    //     break;
                    // case "/pay":
                    //     document.title = "账单详情";
                    //     BROWSER.isclient ? APP.SET_TITLE('账单详情') : document.title = '账单详情';
                    //     break;
                    // case "/payAll":
                    //     window.TDAPP.onEvent("全部结清页面进入");
                    //     BROWSER.isclient ? APP.SET_TITLE('信息确认') : document.title = '信息确认';
                    //     break;
                    // case "/payStage":
                    //     BROWSER.isclient ? APP.SET_TITLE('确认还款金额') : document.title = '确认还款金额';
                    //     break;
                    // case "/payConfirm":
                    //     BROWSER.isclient ? APP.SET_TITLE('支付') : document.title = '支付';
                        //小美租支付页面先注释
                        // window.TDAPP.onEvent("确认付款页面进入");
                    //     break;
                    // case "/payResult":
                    //     BROWSER.isclient ? APP.SET_TITLE('支付') : document.title = '支付';
                    //     break;
                    // case "/loanResult":
                    //     BROWSER.isclient ? APP.SET_TITLE('申请成功') : document.title = '申请成功';
                    //     break;
                    // case "/loanContract":
                    //     BROWSER.isclient ? APP.SET_TITLE('查看协议') : document.title = '查看协议';
                    //     break;
                    // case "/feedBack":
                        // BROWSER.isclient ? APP.SET_TITLE('意见反馈') : document.title = '意见反馈';
                        // break;
                    case "/demo":
                        BROWSER.isclient ? APP.SET_TITLE('接口测试') : document.title = '接口测试';
                        break;
                    case "/recommend":

                        break;
                    default:
                        // document.title = "首页";
                        // BROWSER.isclient ? APP.SET_TITLE('首页') : document.title = '首页';
                        break;
                }
                // 显示title bar
                if (APP.BROWSER.ios && pathname !== '/') {
                    APP.SHOW_TITLE_BAR();
                } else {
                    APP.HIDE_TITLE_BAR();
                }
            })
        }
    }
};
