import ProTypes from 'prop-types';
import React from 'react';
import './index.less';
import APP from '../../utils/APP.js'
class BankList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      iconAdd: require('./imgs/i-bank-add.png'),
      iconNew: require('./imgs/i-bank-new.png'),
      iconChecked: require('./imgs/i-bank-orange-checked.png'),
      currentBankNum: null
    };
  }

  JumpBank() {
    console.log(this.props,8878787)
    this.props.history.push("/bankAdd");
    this.props.onClick && this.props.onClick();
  }

  ChangeBank(item) {
    const {bankAccountFour} = item;
    this.setState({
      currentBankNum: bankAccountFour
    })
  }


  render() {
    const {list, onClick} = this.props;
    const {iconAdd, iconNew, iconChecked, currentBankNum} = this.state;

    console.log(currentBankNum,89898998)

    const RenderBankItem = list && list.map((item, index) => {
      const {bankName, logo, bankAccountFour} = item;
      return (
        <div key={index} onClick={() => {
          this.ChangeBank(item);
          onClick && onClick(item);
        }} className={'m-bank-item border'}>
          <div className={'m-b-icon'}>
            <img alt={'bankIcon'} src={logo}/>
          </div>
          <div className={'m-b-name'}>
            {bankName}({bankAccountFour})
          </div>
          {bankAccountFour === currentBankNum &&
          <div className={'m-b-status'}><img alt={'bankChecked'} src={iconChecked}/></div>}
        </div>
      )
    });

    return (
      <div className={'c-bank-list'}>
        {list && RenderBankItem}
        <div onClick={() => {
          // window.TDAPP.onEvent("借款页-添加新银行卡-进入添加银行卡页");
          this.JumpBank()
        }} className={'m-bank-item border'}>
          <div className={'m-b-icon'}>
            <img alt={'bankIcon'} src={iconAdd}/>
          </div>
          <div className={'m-b-name'}>
            添加新的银行卡
          </div>
          <div className={'m-b-status m-b-status-new'}>
            <img alt={'bankNew'} src={iconNew}/>
          </div>
        </div>
      </div>
    )
  }


}

BankList.propTypes = {
  onClick: ProTypes.func,
  list: ProTypes.array,
  currentBankNum: ProTypes.string
};

export default BankList;
