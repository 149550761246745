// const API = 'http://47.99.245.36:8096';
// const API = 'https://front.jqtxxedk.com';
// const API = 'https://middle.jqtianxia.com';


// const API = '/dev';http://192.168.0.59:8099/
const {API_Portal,API_Front} = require('./env');
// const API = 'http://121.196.26.20:8096';

export default {

  /*Portal 所有接口*/
  interface_url: `${API_Portal}/mac/nokia/member/senVerifyCode`,

  /*Portal 登录*/



  
  /*授权接口*/
  client_auth_get: `${API_Front}/client/getUserCreditInfo`,
  /*借款提交*/
  loan_submit: `${API_Front}/loan/create`,
  /*借款费用计算*/
  loan_fee: `${API_Front}/loan/fee`,
  /*分期数据*/
  init_pay_stage: `${API_Front}/loan/getPlanListByClientNo`,
  /*全部结清数据*/
  init_pay_all: `${API_Front}/loan/getPreLoanPlanClientNo`,


  /*用户银行卡列表*/
  init_Bank_list: `${API_Front}/clientCard/getClientCardList`,


  /*还款短信*/
  init_pay_sms:`${API_Front}/loan/cardBandSms`,

  /*还款*/
  pay_submit:`${API_Front}/loan/backedApply`,

  /*借款协议*/
  loan_contract:`${API_Front}/loan/getLoanAttach`
}


