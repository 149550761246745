const routes = [  
  {
    id: 'myban',
    name: "放假通知",
    desc: '放假通知',
    path: '/myban',
    component: () => import('../routes/myban')
  },
  {
    id: 'procedure',
    name: "租机流程",
    desc: '租机流程',
    path: '/procedure',
    component: () => import('../routes/procedure')
  },
  {
    id: 'question',
    name: "常见问题",
    desc: '常见问题',
    path: '/question',
    component: () => import('../routes/question')
  },
  {
    id: 'orderDetails',
    name: "订单详情",
    desc: '订单详情',
    path: '/orderDetails',
    component: () => import('../routes/orderDetails')
  },
  {
    id: 'billConfirm',
    name: "确认订单",
    desc: '确认订单',
    path: '/billConfirm',
    component: () => import('../routes/billConfirm')
  },
  {
    id: 'checking',
    name: "审核中",
    desc: '审核中',
    path: '/checking',
    component: () => import('../routes/checking')
  },
  {
    id: 'payResult',
    name: "结果页",
    desc: '结果页',
    path: '/payResult',
    component: () => import('../routes/payResult')
  },
  {
    id: 'payConfirm',
    name: "收银台",
    desc: '收银台',
    path: '/payConfirm',
    component: () => import('../routes/payConfirm')
  },
  {
    id: 'Description',
    name: "商品详情",
    desc: '商品详情',
    path: '/Description',
    component: () => import('../routes/Description')
  },
  {
    id: 'prefecture',
    name: "专区",
    desc: '专区',
    path: '/prefecture',
    component: () => import('../routes/prefecture')
  },
  {
    id: 'myBill',
    name: "账单详情",
    desc: '账单详情',
    path: '/myBill',
    component: () => import('../routes/myBill')
  },
  {
    id: 'myAddress',
    name: "我的地址",
    desc: '我的地址',
    path: '/myAddress',
    component: () => import('../routes/myAddress')
  },
  {
    id: 'bankResult',
    name: "支持的银行卡",
    desc: '支持的银行卡',
    path: '/bankResult',
    component: () => import('../routes/bankResult')
  },
  {
    id: 'bankAdd',
    name: "添加银行卡",
    desc: '添加银行卡',
    path: '/bankAdd',
    component: () => import('../routes/bankAdd')
  },
  {
    id: 'bank',
    name: "我的银行卡",
    desc: '我的银行卡',
    path: '/bank',
    component: () => import('../routes/bank')
  },
  {
    id: 'newAddress',
    name: "添加新地址",
    desc: '添加新地址',
    path: '/newAddress',
    component: () => import('../routes/newAddress')
  },
  {
    id: 'address',
    name: "地址管理",
    desc: '地址管理',
    path: '/address',
    component: () => import('../routes/address')
  },
  {
    id: 'setting',
    name: "设置",
    desc: '设置',
    path: '/setting',
    component: () => import('../routes/setting')
  },
  {
    id: 'login',
    name: "登录页",
    desc: '登录页',
    path: '/login',
    component: () => import('../routes/login')
  },
  {
    id: 'authResult',
    name: "认证成功",
    desc: '认证成功',
    path: '/authResult',
    component: () => import('../routes/authResult')
  },
  {
    id: 'baseInfo',
    name: "基础信息",
    desc: '基础信息',
    path: '/baseInfo',
    component: () => import('../routes/baseInfo')
  } ,
  {
    id: 'authFace',
    name: "活体列表",
    desc: '活体列表',
    path: '/authFace',
    component: () => import('../routes/authFace')
  } ,
  {
    id: 'authIdentity',
    name: "实名认证",
    desc: '实名认证',
    path: '/authIdentity',
    component: () => import('../routes/authIdentity')
  } ,
  {
    id: 'auth',
    name: "认证列表",
    desc: '认证列表',
    path: '/auth',
    component: () => import('../routes/auth')
  } ,
  {
    id: 'bill',
    name: "我的账单",
    desc: '我的账单',
    path: '/bill',
    component: () => import('../routes/bill')
  },
  // {
  //   id: 'index',
  //   name: "首页",
  //   desc: '首页',
  //   path: '/index',
  //   component: () => import('../routes/index')
  // },
  {
    id: 'my',
    name: "我的",
    desc: '我的',
    path: '/my',
    component: () => import('../routes/my')
  },
  {
    id: 'freeDeposit',
    name: "首次确认订单",
    path: '/freeDeposit',
    component: () => import('../routes/freeDeposit')
  },
  {
    id: 'freeGas',
    name: "押金测算",
    path: '/freeGas',
    component: () => import('../routes/freeGas')
  },
  {
    id: 'freeResult',
    name: "结果页",
    path: '/freeResult',
    component: () => import('../routes/freeResult')
  },
  {
    id: 'pdfView',
    name: "协议预览",
    path: '/pdfView',
    component: () => import('../routes/pdfView')
  },
];


export default routes;
