
import React from 'react';
import { TabBar } from 'antd-mobile';
import localStorage from 'localStorage';
const TabBarItem = TabBar.Item;
export default class TabBarComponent extends React.Component {

  constructor(...props) {
    super(...props);
    this.state = {
      selectedTab: 'index',
      user:null
    };
  }

  componentDidMount() {
    // console.log( window.localStorage.getItem("tabBarName"),7777)

    let currentTabName = window.localStorage.getItem('tabBarName');
    if (currentTabName) {
      this.setState({
        selectedTab: currentTabName
      })
    }
    // const user=localStorage.getItem('userList');
    // this.setState({
    //   user
    // })
    // console.log(user,99991)
  }

  render() {
    // const { user } = this.state;
    return (
      <div className={'tabbar'}>
        <TabBar
          unselectedTintColor="#9B9B9B"
          tintColor="#4A4A4A"
          barTintColor="white"
        >
          <TabBarItem
            title="首页"
            key="index"
            icon={{uri:'https://file.jqtianxia.com/xmz-h5/indexA.png '}}
            selectedIcon={{uri: 'https://file.jqtianxia.com/xmz-h5/indexB.png'}}
            selected={this.state.selectedTab === 'index'}
            onPress={() => {
              this.setState({
                selectedTab: 'index',
              });
              window.localStorage.setItem("tabBarName", "index");
              this.props.history.push("/");
            }}
          ></TabBarItem>

          <TabBarItem
            title="订单"
            key="bill"
            icon={{uri: 'https://file.jqtianxia.com/xmz-h5/billA.png'}}
            selectedIcon={{uri: 'https://file.jqtianxia.com/xmz-h5/billB.png'}}
            selected={this.state.selectedTab === 'bill'}
            onPress={() => {
              this.setState({
                selectedTab: 'bill',
              });
              window.localStorage.setItem("tabBarName", "bill");
              this.props.history.push("/bill");
            }}
          ></TabBarItem>

          <TabBar.Item  
            title="我的"
            key="my"
            icon={{uri: 'https://file.jqtianxia.com/xmz-h5/myA.png'}}
            selectedIcon={{uri: 'https://file.jqtianxia.com/xmz-h5/myB.png'}}
            selected={this.state.selectedTab === 'my'}
            onPress={() => {
        
              // if (this.state.user !== undefined && this.state.user !== null && this.state.user.length > 0) {
                this.setState({
                  selectedTab: 'my',
                });
                window.localStorage.setItem("tabBarName", "my");
                this.props.history.push("/my");
                
              // } else {
              //   this.props.history.push('/login')
              // }
                
          
            }}
          ></TabBar.Item>
        </TabBar>
      </div>
    );
  }
};


TabBarComponent.propTypes = {};
