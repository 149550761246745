import React from 'react';
import {NavBar, Icon} from 'antd-mobile';
import './layout.css'

const NavBarComponent = ({nav, history}) => {
const cls = nav.className ? `${nav.className} nav-bar` : 'nav-bar';
let brandId =  JSON.parse(window.localStorage.getItem("brandId"))  ;
let ptitle = '';


switch (Number(brandId)) {
    case 1:
        ptitle = '苹果专区';
        break;
    case 2:
        ptitle = '华为专区';
        break;
    case 3:
        ptitle = '二手好物';
        break;
    case 4:
      ptitle = '商品详情';
      break;
    default:
        ptitle = nav.title ;
        break;
};


  return (
    <NavBar
      mode={nav.mode ? nav.mode : 'light'}
      className={cls}
      icon={nav.left && <Icon type="left"/>}
    
      onLeftClick={(event) => {
        if (nav.left==true) {
          history.goBack();
        }
      
      }}
      rightContent={nav.right}
    >{ ptitle ? ptitle : nav.title }</NavBar>
  )
}


NavBarComponent.propTypes = {};

export default NavBarComponent;
