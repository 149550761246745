import ProTypes from 'prop-types';
import React from 'react';
import './index.less';

const Btn = ({children, type = 'primary', className, onClick}) => {

  let cls = className ? `${className} x-btn-inner` : `x-btn-inner`;

  switch (type) {
    case 'primary':
      cls += ' x-btn-primary';
      break;
    case 'disabled':
      cls += ' x-btn-disabled';
      break;
    default:
      break;
  }


  return (
    <div className={'x-btn'} onClick={(e) => {
      if (type !== 'disabled') {
        onClick && onClick(e);
      }
    }}>
      <div className={cls}>{children}</div>
    </div>
  )
};

Btn.propTypes = {
  onClick: ProTypes.func,
  type: ProTypes.string,
  className: ProTypes.string
};

export default Btn;
