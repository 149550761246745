import PropTypes from 'prop-types'
import React from 'react';
import { Flex } from 'antd-mobile';
import './index.less';

const FlexItem = Flex.Item;

class LineCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  switchStatus(checked) {
    this.props.onClick && this.props.onClick(!checked);
  }

  render() {
    const { checked } = this.props;
    const { title, label, type, extra, className } = this.props;
    let cls = className ? `${className} a-line` : `a-line`;

    /*是否选中*/
    let RenderRight
    // let RenderRight = <div className={'l-right-check check-false'}></div>;
    if (checked) {
      // RenderRight = <div className={'l-right-check check-true'}></div>
    }

    /*当期状态*/
    let typeCls = 'l-l-label';
    let moneyCls = 'l-right-money';
    if (type) {
      switch (type) {
        // 逾期
        case 'danger':
          typeCls += ' l-label-danger';
          cls += ' a-line-danger';
          break;
        // 当前期
        case 'primary':
          typeCls += ' l-label-primary';
          cls += ' a-line-primary';
          break;
        // 已结清
        case 'grey':
          typeCls += ' l-label-grey';
          moneyCls += ' l-right-grey';
          cls += ' a-line-grey';
          // RenderRight = <div className={'l-right-check check-false'}></div>;
          break;
        // 未到期
        default:
          typeCls += ' l-label-default';
          // RenderRight = <div className={'l-right-check check-false'}></div>;
          break;
      }
    }


    return (
      <div className={cls}>
        <Flex
          onClick={() => {
            if (type !== 'grey') {
              this.switchStatus(checked);
            }
          }}
          justify={'center'} align={'center'} className={'a-inner'}>
          <FlexItem>
            <div className={'l-left'}>
              <span className={'l-l-title'}>{title}</span>
              {label && <span className={typeCls}>{`(${label})`}</span>}
            </div>
          </FlexItem>
          <FlexItem>
            <div className={'l-right'}>
              <div className={moneyCls}>{extra}</div>
              {RenderRight}
            </div>
          </FlexItem>
        </Flex>
      </div>
    )
  }

}


LineCheck.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  extra: PropTypes.string,
  className: PropTypes.string
};

export default LineCheck;
