import axios from 'axios';

const baseUrl = 'https://mac.senlianshop.com/mac/'  
// const baseUrl = '/dev/'
const fecth = (url, data, method = 'post') => {
    url = `${baseUrl}${url}`
    let headers = localStorage.getItem('accessToken') ? {'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, 'x-tenant-header': '10000'} : {'x-tenant-header': '10000'}
    return new Promise((resolve, reject) => {
        axios({
            url,
            method,
            data,
            headers,
        }).then(({data}) => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    }).then(res => {
        return res
    })


}

export default fecth