import axios from 'axios'
import qs from 'qs';
import Cookies from "js-cookie";
import APP from './APP';
import {BaseUrl} from "./index";
import {desCode} from "./encryption";

const {Env} = require('../config/env');

const isEncryption = Env === 'prod';
// 添加请求拦截器，提交的时候加密
axios.interceptors.request.use(function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    });

// 添加返回拦截，返回的时候解密
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

/*Portal*/
const FetchPortal = async (options) => {
    let {data, url} = options;
    let rd = Cookies.get("rd");

    if (data.params) {
        rd = JSON.stringify(Object.assign({}, data.params, JSON.parse(rd)));
        delete data.params;
    }
    if (Env === "prod") {
        data.rd = desCode.ncrypted(rd);
    } else {
        data.rd = rd;
    }

    
    
    return axios.post(url, data, {
            transformRequest: [function (data) {
                data = qs.stringify(data);
                return data;
            }],
            withCredentials: true
        }
    );
};

/*Front*/
const FetchFront = async (options) => {
    let {method = 'get', data, url} = options;
    const clientNo = window.localStorage.getItem('clientNo');
    if (clientNo) {
        data['clientNo'] = clientNo;
    }
    let fetch;
    switch (method.toLowerCase()) {
        case 'get':
            fetch = axios.get(url,
                {
                    params: data,
                    withCredentials: true
                });
            break;
        case 'post':
            fetch = axios.post(
                url,
                data,
                {
                    withCredentials: true
                });
            break;
        default:
            fetch = axios({...options});
            break;
    }
    return fetch;
};

export default async function request(options) {
    let fetchFn;
      
    const v = JSON.stringify({
        "appName": "MMZJ",
        "appSourceId": 1,
        "device":'H5'
    });
    Cookies.set('rd', v);
    fetchFn = FetchPortal;
 
    
    return fetchFn(options)
    .then((response) => {
        
        const {config} = response;
        const {url} = config;
        let data = response.data;

        console.log(config,888888)

        if (data.code === '500') {
            throw new Error(data.message);
        }

        
        // if (url.indexOf(BaseUrl.Portal) !== -1) {
            const { desData } = data;
            // 判断未登录的状态
            if(data.timeout){
                // options.url
                let rurl = 'https://mtest.senlianshop.com/#/login';
                if(Env === "prod" ){
                    rurl = "https://mtest.senlianshop.com/#/login";
                }else{
                    // rurl =  'http://192.168.0.85/#/login';
                    rurl =  'https://mtest.senlianshop.com/#/login';
                }
                window.location.href=rurl ;
                return false;
            }

            
            if (!data.succ) {
                throw new Error(data.message);
            }
            // 如果是生成环境，就解密
            if (Env === "prod") {
                data.desData = desCode.decrypted(desData);
            }
            
            return Promise.resolve({...data})
            
        // } else {
        //     console.log(data,2222)
        //     return Promise.resolve({...data})
        // }
        
        
    })
    .catch((error) => {
        const {response} = error;
        let msg;
        let statusCode;
        if (response && response instanceof Object) {
            const {data, statusText} = response;
            statusCode = response.status;
            msg = data.message || statusText;
        } else {
            statusCode = 600;
            msg = error.message || '服务器开小差了...';
            if (error.message === "Network Error") {
                msg = "服务器开小差了"
            }
        }
        
        /* eslint-disable */
        return Promise.reject({succ: false, statusCode, message: msg})
    })
}
