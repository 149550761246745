import React from 'react';
import { Toast } from 'antd-mobile';
import APP from '../../utils/APP';
import './index.less';
import { Domain } from '../../config/env';

const AuthLine = ({ data, facedata ,callback}) => {

  const JumpPage = (obj) => {
    let flag = false;
    data.forEach((item) => {
      if (item.type === "IDENTITY" && item.status === "COMPLETED") {
        flag = true;
      }
    });

    const { type } = obj;
    switch (type) {
      case "IDENTITY":
        callback && callback('identity');
        break;
        // APP.JUMP('winwallet://page/realAuth');
        // break;
      case "BASIC_INFO":
        if (!flag) {
          Toast.info('请先进行实名认证', 1.5);
          
        } else {
          callback && callback('baseInfo');
        }
        break;
        // if (!flag) {
        //   Toast.info('请先进行实名认证', 1.5);
        // } else {
        //   APP.JUMP(`${Domain}/index.html#/baseInfo`, '基础信息');
        // }
        // break;
      default:
        if (!flag) {
          Toast.info('请先进行实名认证', 1.5);
        } else {
          callback && callback('face');
          

        }
        break;
        // if (!flag) {
        //   Toast.info('请先进行实名认证', 1.5);
        // } else {
        //   // 判断当前用户已经多少次活体
        //   if (facedata) {
        //     Toast.info('认证次数已达上限，请联系客服处理', 1.5);
        //   } else {
        //     APP.JUMP('winwallet://page/faceAuth');
        //   }
        // }
        break;
    }
  };


  const RenderAuthLine = data && data.map((item, index) => {
    const { type, name, status, text } = item;
    let iconCls = 'a-i-left';
    switch (type) {
      case "IDENTITY":
        iconCls += ' a-i-name';
        break;
      case "FACE":
        iconCls += ' a-i-live';
        break;
      case "BASIC_INFO":
        iconCls += ' a-i-info';
        break;
      default:
        iconCls += "";
        break;
    }

    let rightColorCls = "a-i-right-text";
    switch (status) {
      case "COMPLETED":
        // #108EE9
        rightColorCls += " gray";
        break;
      case "ERROR":
        // #FF4248
        rightColorCls += " red";
        break;
      default:
        // UNCOMPLETED
        // #757575 #212121
        rightColorCls += "  blue";
        break;
    }

    return (
      <div key={index} onClick={() => {
        if (item.status !== "COMPLETED") {
          JumpPage(item);
        }
      }} className={'a-item'}>
        <div className={iconCls}>
          <span>{name}</span>
        </div>
        <div className={'a-i-right'}>
          <span className={rightColorCls}>{text}</span>
        </div>
      </div>
    )
  });


  return (
    <div className={'a-list'}>
      {RenderAuthLine}
    </div>

  )
};
AuthLine.propTypes = {};

export default AuthLine;
