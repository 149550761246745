export const money = (s, n = 2) => {
  if (!s) s = 0;
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
  let l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
};

//新增
export const formatCertDate = (date) => {
  if(!date) date = 0;
  const dataArr = date.split('-')[1];
  const validArr = dataArr.split('.');
  return validArr.join('/');
};
export const SearchParams = (location) => {
  return new URLSearchParams(location);
};
