import request from './fetch';
import {Toast} from "antd-mobile";
import api from '../config/api';
import {money, SearchParams} from './filter';
import {Domain, Env} from '../config/env';


/*APP方法调转H5，传参*/
export const H5Url = (path, params) => {
    let url = `${Domain}/index.html`;
    if (params) {
        url += '?';
        for (let [key, value] of Object.entries(params)) {
            url += `${key}=${value}&`;
        }
    }
    const lastIndex = url.lastIndexOf("&");
    if (lastIndex !== -1) {
        url = url.substring(0, lastIndex);
    }
    
    url += `#/${path}`;
    return url;
};

/*Front 用 借款还款携带 ClientNo*/
export const ChangeUrlH5 = (url, clientNo) => {
    const urlArr = url.split('#');
    return `${urlArr[0]}?clientNo=${clientNo}#${urlArr[1]}`;
};

/*服务端接口*/
let ServerConfig = {
    // 更换项目调试需要更换portals 接口地址
    // Front: 'http://192.168.2.40:8096',
    //测试地址https://bullet-test.senlianshop.com/app/apiGateway.do/
 
    // portal:'https://bullet-test.senlianshop.com'
    portal:'https://mac-s.senlianshop.net'
    // 线上地址
    // Portal: 'https://portals.senlianshop.com'
};
if (Env === "prod") {
    // mmzj
    ServerConfig = {
        //测试
        portal:'https://mac-s.senlianshop.net'
        // portal:'https://bullet-test.senlianshop.com'//原有
        // Front: 'https://portaltest.senlianshop.com',
        // Portal: 'https://portaltest.senlianshop.com',
        //线上
        // Front: 'https://portals.senlianshop.com',
        // Portal: 'https://portals.senlianshop.com'
    };

}

export const BaseUrl = ServerConfig;

export {
    request,
    api,
    money,
    SearchParams
}
