import React from 'react';
import {withRouter} from 'dva/router';
import {connect} from 'dva';

import NavBar from './navBar';
import TabBar from './tabBar';
import './layout.css';

const Layout = ({history, style, className, navigation, tabBar, children}) => {
  let clsName = className ? `page ${className}` : 'page';
  /* 在这里做权限判断，如果cookie过期的话，就重定向到login页面*/
  return (
    <div style={style} className={'main'}>
      {/* 导航栏 */}
      {navigation && <NavBar history={history} nav={navigation}/>}

      {/* 页面内容 */}
      <div className={clsName}>{children}</div>

      {/*标签栏目*/}
      {tabBar && <TabBar isShow={false} history={history}/>}
      {/*{<TabBar isShow={false} history={history}/>}*/}
    </div>
  )
}

Layout.propTypes = {};

export default withRouter(connect(({NSApp}) => ({NSApp}))(Layout));
