import React from 'react';
import {Flex, Icon} from 'antd-mobile';
import './index.less';

const FlexItem = Flex.Item;
const Panel = ({title, icon, onClick, extra, className}) => {

  let cls = className ? `${className} a-line` : `a-line`;

  if (icon) {
    cls += " a-line-icon"
  }


  let RenderRight = <FlexItem className={'l-right'} align={'right'}></FlexItem>;

  if (extra && onClick) {
    RenderRight = <FlexItem className={'l-right'} align={'right'}>
      <p>{extra}</p>
      <Icon type="right" color={'#C2C2C2'} size={'md'}/>
    </FlexItem>;
  }else if(extra){
    RenderRight = <FlexItem className={'l-right'} align={'right'}>
      <p className={'l-r-txt'}>{extra}</p>
    </FlexItem>;
  }else if(onClick){
    RenderRight = <FlexItem className={'l-right'} align={'right'}>
      <Icon type="right" color={'#C2C2C2'} size={'md'}/>
    </FlexItem>;
  }

  return (
    <div className={cls}>
      <Flex
        onClick={(e) => {
          onClick && onClick(e);
        }}
        justify={'center'} align={'center'} className={'a-inner'}>
        <FlexItem>
          <div className={'a-line-item'}>
            <span>{title}</span>
          </div>
        </FlexItem>
        {RenderRight}
      </Flex>
    </div>
  )
};
Panel.propTypes = {};

export default Panel;
